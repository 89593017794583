import { DruideModal } from "@yoshteq/druide-webcomponents";
import { KimMail, KimMailAttachmentInfo, KimMessageLogType, MailAddressType, MailObject } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { svgAttachment, svgDelete, svgError, svgInfo, svgReply, svgWarning } from "../Components/SvgConst";
import { byteToHuman } from "../Functions";

@customElement("kim-mail-view")
export class KimMailView extends LitElement {
    static styles = css`
    :host {
        box-sizing: border-box;
    }
    .mail-header{
      margin-bottom:10px;
      justify-content: space-between;
    }
    .receivers{
      margin-bottom:10px;
    }
    .address-list{ 
      font-size:0.8em;
    }
    .address-list > .title{
      font-weight:bold;
      display:inline-block;
      width:2.5em;
    }
    .address{
      cursor:help;
    }
    .address + .address::before{
      content:", "

    }
    .attachments{
      display:flex;
      flex-wrap:wrap;
      gap:10px;
      margin-bottom:10px;
    }
    .attachment{
      font-size:0.8em;
      border:1px solid var(--divider-color);
      border-radius: var(--card-radius);
      display: grid;
      padding: 0.5rem;
      padding-right:1rem;
      cursor:pointer;
      min-width:100px;
      max-width:200px;
      grid-template-areas:
          "icon  name"
          "icon size";
      grid-template-columns: min-content 1fr;
      transition: 0.25s;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }
    .attachment:hover{
        background: #ffe2ff;
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.20), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); 
    }
    .attachment > svg {
      grid-area:icon;
      align-self:center;
      width:2em;
      height:2em;
      margin-right:0.5rem;
    }
    .attachment > .name {
      grid-area:name;
    }
    .attachment > .size {
      grid-area:size;

    }
    .mail-warning{
      display:flex;
      padding:0.5em;
      border-radius: var(--card-radius, 1.5rem);
      align-items: center;
      gap: 0.5em;
    }
    .mail-warning.error {
      background-color: var(--error-color);
    }
    .mail-warning.warn {
      background-color: var(--warning-color, orange);
    }
    .mail-warning.info {
      background-color: blue;
    }
    .mail-warning > span{
      flex: 1 1 ;
      color: var(--error-text-color);
    }
    .mail-warning > svg{
      height:1.5em;
      width:1.5em;
      fill: var(--error-text-color);
    }
`;

    constructor(mail: KimMail | MailObject) {
        super();
        this.mail = mail;
    }

    @state()
    public mail: KimMail | MailObject;

    @query("#deleteMailDialog")
    private deleteMailDialog!: DruideModal;

    protected override render(): TemplateResult {
        if (!this.mail) {
            return html``;
        }
        let mailHtml: string | undefined = undefined;

        if (this.mail.isHtmlBody) {
            mailHtml = this.mail.body.replace("<body", "<style> body{ margin: 0; }</style><body");
        } else {
            mailHtml = `<html><body style="margin:0;"><pre>${this.mail.body}</pre></body></html>`;
        }
        return html`
      <druide-card label=${this.mail.subject}  >
      <div slot="header-action">
        <druide-icon-button  @click=${this.reply} title="Antworten">${svgReply}</druide-icon-button>
        <druide-icon-button  @click=${() => this.deleteMailDialog.show()} title="Löschen">${svgDelete}</druide-icon-button>
      </div>
        <div class="mail-header">
          ${this.renderWarning("error")}
          ${this.renderWarning("warn")}
          ${this.renderWarning("info")}
          <div class="receivers">
            ${this.renderAddresses("Von:", [this.mail.from])}
            ${this.renderAddresses("An:", this.mail.recipientsTo)}
            ${this.renderAddresses("CC:", this.mail.recipientsCc)}
          </div>
        </div>
        ${this.renderAttachments()}       
        ${mailHtml ? html`<druide-html-view style="height:100%;" .html=${mailHtml} auto-height></druide-html-view>` : html`<loader-message>Nachricht wird geladen</loader-message>`}
        
      </druide-card>    
      <druide-modal id="deleteMailDialog" close-explicit>       
         <druide-card >
            <p>
              Möchten Sie diese KiM Nachricht wirklich löschen?
            </p>
            <druide-button slot="footer-left"  @click=${() => this.deleteMailDialog.hide()}>Nein</druide-button> 
            <druide-button slot="footer-right" @click=${() => this.deleteMail()}>Löschen</druide-button> 
        </druide-card>
    </druide-modal>`;
    }

    private renderWarning(type: KimMessageLogType) {
        const logs = this.mail.processingWarnings.filter((e) => e.type === type);
        if (logs && logs.length > 0) {
            return html`<div class="mail-warning ${type}">${this.getMessageLogTypeIcon(type)} <span>${logs.map((e) => e.message).join(", ")}</span></div>`;
        }
    }

    private getMessageLogTypeIcon(type: KimMessageLogType) {
        switch (type) {
            case "error":
                return svgError;
            case "warn":
                return svgWarning;
            case "info":
                return svgInfo;
        }
    }

    private renderAttachments() {
        if (this.mail?.attachmentInfos && this.mail.attachmentInfos.length > 0) {
            return html`
        <div class="attachments" title="">
          ${this.mail.attachmentInfos.map((a) => this.renderAttachment(a))}
        </div>`;
        }
        return undefined;
    }
    private renderAttachment(mailAttachment: KimMailAttachmentInfo) {
        return html`
      <div class="attachment" @click=${() => this.downloadAttachment(mailAttachment)}>
        ${svgAttachment} 
        <div class="name">${mailAttachment.title}</div>
        <div class="size">${byteToHuman(mailAttachment.size)} </div>
      </div>`;
    }
    private async downloadAttachment(attachment: KimMailAttachmentInfo) {
        if (this.mail) {
            this.dispatchEvent(new CustomEvent("download-attachment", { composed: true, bubbles: true, detail: { storageId: this.mail.storageId, attachment } }));
        }
    }

    private reply() {
        this.dispatchEvent(new CustomEvent("mail-reply", { detail: this.mail?.storageId, composed: true }));
    }

    private async deleteMail() {
        if (this.mail) {
            this.dispatchEvent(new CustomEvent("delete-kim-mail", { composed: true, bubbles: true, detail: { mail: this.mail, onSuccess: () => this.deleteMailDialog.hide() } }));
        }
    }

    private renderAddresses(title: string, addresses: MailAddressType[] | undefined) {
        if (addresses && addresses.length > 0) {
            return html`<div class="address-list"><span class="title">${title}</span> ${addresses.map((adr) => this.renderAddress(adr))}</div>`;
        } else {
            return undefined;
        }
    }

    private renderAddress(adr: MailAddressType) {
        return html`<span class="address" title=${adr.address}>${adr.name || adr.address}</span>`;
    }
}
