import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, query } from "lit/decorators.js";

@customElement("master-detail-layout")
export default class MasterDetailLayout extends LitElement {
    static styles = css`
    :host{
        height:100%;
        display:block;
        --l-header-height: var(--header-height,  50px);
        --l-navigation-width: var(--navigation-width, 300px);
    }

    main {
        display: grid;
        grid-template-columns: var( --l-navigation-width) 1fr;
    }

    nav {
        position: sticky;
        top: var(--l-header-height);
        height: calc(100vh - var(--l-header-height));
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        overflow-y: auto;
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 5px 0px;
    }

    article {
        display: flex;
        flex-direction: column;
        min-width: 0;
    }

    @media only screen and (min-width: 600px) {
        /* Desktop */

        .back-header {
            display: none;
        }
    }

    @media only screen and (max-width: 600px) {

        /* Mobile */
        main {
            display: block;
            width: 100%;
        }

        main> :not(.active) {
            display: none;
        }
    }`;

    @query("nav")
    private nav!: HTMLElement;
    @query("article")
    private content!: HTMLElement;

    public setContent(element: Element) {

        const removeList: Element[] = [];
        for (const child of this.children || []) {
            if (child.slot === "content") {
                removeList.push(child);
            }
        }
        removeList.forEach((e) => this.removeChild(e));
        element.slot = "content";
        this.appendChild(element);
        this.showContent();
    }

    public showContent() {
        this.content.classList.add("active");
        this.nav.classList.remove("active");
    }
    public isNavigationVisible() {
        return this.nav.offsetParent !== null;
    }

    public showNavigation() {
        this.nav.classList.add("active");
        this.content.classList.remove("active");
    }

    protected override render(): TemplateResult {
        return html`
            <main>
                <nav class="active">
                    <slot name="navigation"></slot>
                </nav>
                <article>
                    <slot name="content"></slot>
                </article>
            </main>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "master-detail-layout": MasterDetailLayout;
    }
}
